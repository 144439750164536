import paypalSupportedCurrencies from "./paypalSupportedCurrencies";
import countryByCurrencyCode from "./country-by-currency-code.json"

// RUB not supported by paypal and there's no handler yet for it
const notSupportedCurrencies = ["RUB"]

// India INR not supported yet by Paypal, but we'll handle that separately from Paypal
const INRCurrency = countryByCurrencyCode.find(c => c.currency_code === "INR")

const supportedCurrencies = [...paypalSupportedCurrencies, INRCurrency].filter(c => !notSupportedCurrencies.includes(c.currency_code))

export default supportedCurrencies