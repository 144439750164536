import Vue from "vue";

export default {
  processDates: (payload, timezone) => {
    let till = Vue.moment(payload.till * 1000)
      .tz(timezone)
      .endOf("day");
    let since = Vue.moment(payload.since * 1000)
      .tz(timezone)
      .startOf("day");
    let hours = Vue.moment(till).diff(Vue.moment(since), "hours");
    let days = Vue.moment(till).diff(Vue.moment(since), "days");
    let set = [];
    let mode = "day";
    let modeFormat = "ddd, MMM D";
    let count = 0;

    if (hours <= 24) {
      mode = "hour";
      modeFormat = "hA";
    } else {
      if (days <= 40) {
        mode = "day";
        modeFormat = "ddd, MMM D";
      } else if (days > 40 && days <= 180) {
        mode = "week";
        modeFormat = "MMM Do";
      } else if (days > 180 && days <= 720) {
        mode = "month";
        modeFormat = "MMM YYYY";
      } else {
        mode = "year";
        modeFormat = "YYYY";
      }
    }

    till = Vue.moment(till.endOf(mode).toDate());

    while (true) {
      if (mode === "hour") {
        if (count > 23) {
          break;
        }
      } else {
        if (since > till) {
          break;
        }
      }

      let sinceSet;
      let tillSet;
      let timeSet;

      sinceSet = Vue.moment(
        since
          .startOf(mode)
          .toDate()
          .toISOString()
      ).unix();
      tillSet = Vue.moment(
        since
          .endOf(mode)
          .toDate()
          .toISOString()
      ).unix();
      if (mode === "week") {
        timeSet =
          "Week of " +
          Vue.moment(
            since
              .startOf(mode)
              .add(1, "day")
              .toDate()
              .toISOString()
          ).format(modeFormat);
      } else {
        timeSet =
          Vue.moment(
            since
              .startOf(mode)
              .toDate()
              .toISOString()
          ).format(modeFormat) + "";
      }
      since = since.add(1, mode);
      set.push({
        sinceSet,
        tillSet,
        timeSet,
      });
      count++;
    }

    return set;
  },
  processLogDates: (since, till) => {
    let hours = Vue.moment(till).diff(Vue.moment(since), "hours");
    let set = [];
  },
  setSinceDate: (date) => {
    return Vue.moment(date * 1000)
      .startOf("day")
      .unix();
  },
  setTillDate: (date) => {
    return Vue.moment(date * 1000)
      .endOf("day")
      .unix();
  },
};
