<template>
  <v-container class="text-xs-center">
    <div class="document-container">
      <v-layout row>
        <v-flex xs12 class="text-xs-left">
          <img id="logo" src="../assets/PlugzioLogoHorizontal.png" height="30px" />
        </v-flex>
        <v-flex xs12 class="text-xs-right">
          <v-btn small dark color="green" class="mr-0 mt-0 plugzio-button" round outline @click.stop.prevent="back()">
            {{ history ? "BACK" : "HOME" }}
          </v-btn>
        </v-flex>
      </v-layout>
      <v-card class="text-xs-justify mt-3 pa-5 pb-0 document">
        <slot></slot>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import mixin from "./../mixins";

export default {
  mixins: [mixin],
  computed: {
    history() {
      return window.history.length > 2;
    },
  },
  methods: {
    back() {
      this.history ? this.$router.back() : this.$router.push({ name: `${this.modeRoute}dashboard` });
    },
  },
};
</script>
