export default [
  {
    "country": "Australia",
    "currency_code": "AUD"
  },
  {
    "country": "Brazil",
    "currency_code": "BRL"
  },
  {
    "country": "Canada",
    "currency_code": "CAD"
  },
  {
    "country": "China",
    "currency_code": "CNY"
  },
  {
    "country": "Czech",
    "currency_code": "CZK"
  },
  {
    "country": "Denmark",
    "currency_code": "DKK"
  },
  {
    "country": "EURO",
    "currency_code": "EUR"
  },
  {
    "country": "Hong Kong",
    "currency_code": "HKD"
  },
  {
    "country": "Hungary",
    "currency_code": "HUF"
  },
  {
    "country": "Israel",
    "currency_code": "ILS"
  },
  {
    "country": "Japan",
    "currency_code": "JPY"
  },
  {
    "country": "Malaysia",
    "currency_code": "MYR"
  },
  {
    "country": "Mexico",
    "currency_code": "MXN"
  },
  {
    "country": "Taiwan",
    "currency_code": "TWD"
  },
  {
    "country": "New Zealand",
    "currency_code": "NZD"
  },
  {
    "country": "Norway",
    "currency_code": "NOK"
  },
  {
    "country": "Philippines",
    "currency_code": "PHP"
  },
  {
    "country": "Poland",
    "currency_code": "PLN"
  },
  {
    "country": "United Kingdom",
    "currency_code": "GBP"
  },
  // RUB suddenly not supported anymore
  // {
  //   "country": "Russian Federation",
  //   "currency_code": "RUB"
  // },
  {
    "country": "Singapore",
    "currency_code": "SGD"
  },
  {
    "country": "Sweden",
    "currency_code": "SEK"
  },
  {
    "country": "Switzerland",
    "currency_code": "CHF"
  },
  {
    "country": "Thailand",
    "currency_code": "THB"
  },
  {
    "country": "United States",
    "currency_code": "USD"
  },
]