<template>
  <div>
    <h4 class="mb-4">{{ !visibleWallets && !isOwner ? "You Have No Visible Wallets" : "You Have No Wallets" }}</h4>
    <div class="notice-description mb-4 text-xs-left">
      <p class="mb-4" v-if="isOwner">
        Wallets allow you to receive and store payments from users.
        <br />Wallets are also required in order to create payment plans.
      </p>
      <p></p>
      <div class="mt-2" v-if="!isOwner">
        {{ !visibleWallets ? "Wallets allow you to:" : "Why you should make a wallet:" }}
        <ul class="mt-2">
          <li>Wallets conveniently allow you to pay for devices.</li>
          <li>Wallets work via a top up and pay as you go system.</li>
          <li>
            You will only be charged for what you use. Prices and rates set by the manager of the devices if applicable.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOwner: {
      type: Boolean,
    },
    visibleWallets: {
      type: Boolean,
    },
  },
};
</script>
