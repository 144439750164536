<template>
  <v-container text-xs-center fill-height class="px-3">
    <v-layout row wrap align-center>
      <v-flex v-show="!busy">
        <div class="login-logo mt-0" v-if="logo">
          <img src="../assets/PlugzioLogoHorizontal.png" />
        </div>
        <v-card class="login-card elevation-1">
          <slot></slot>
        </v-card>
      </v-flex>
      <v-flex v-show="busy">
        <v-progress-circular class="loading-circle" size="50" indeterminate color="orange" />
        <div v-if="status" class="text-xs-center mt-3">{{ status }}</div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    busy: {
      default: false,
    },
    status: {
      default: "",
    },
    logo: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.login-logo {
  padding: 0 30px;
}
</style>
