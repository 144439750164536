<template>
  <div v-if="data.length < 1">
    <span>No withdrawal profile available. Please add a withdrawal profile before submitting a withdrawal request.</span>
  </div>
</template>

<script>
export default {
  name: "ZeroWithdrawalProfiles",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
