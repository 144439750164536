<template>
  <v-list-tile
    avatar
    class="session-tile recent-plugs"
    :class="{
      'session-active': session.statusCode == 1,
      'session-stale': session.statusCode == 2,
    }"
  >
    <v-list-tile-avatar>
      <div class="plug-avatar">
        <img src="./../../assets/plug-off.png" v-if="session.statusCode == 0" />
        <img src="./../../assets/plug-on.png" v-else-if="session.statusCode == 1" />
        <img src="./../../assets/not-allowed.svg" class="pa-2" v-else />
      </div>
    </v-list-tile-avatar>
    <v-list-tile-content class="plug-tile-details">
      <div :id="`recent-session-${session.id}-device`">
        Device ID:<span class="black--text ml-1">{{ session.plug_identifier }}</span>
      </div>
      <div :id="`recent-session-${session.id}-status`">
        Status:
        <span class="status ml-1" :class="session.active ? session.class : 'black--text'">{{ session.status }}</span>
      </div>
      <div :id="`recent-session-${session.id}-consumption`">
        Consumption:
        <span class="black--text ml-1">
          {{ session.active ? session.consumption : "- kWh" }}
        </span>
      </div>
      <div :id="`recent-session-${session.id}-duration`">
        Duration:
        <span class="black--text ml-1">
          {{ session.duration || "00:00:00" }}
        </span>
      </div>
      <div :id="`recent-session-${session.id}-last-updated`">
        Last Updated:
        <span class="black--text ml-1">
          {{ session.update_formatted }}
        </span>
      </div>
    </v-list-tile-content>
    <v-list-tile-action>
      <VTooltipHandler v-if="session.statusCode == 2" icon="info" text="Session has not been updated recently. Device maybe offline or powered off." classes="session-stale-info" />
      <v-switch
        v-if="responsive"
        :id="`recent-session-${session.id}-turn-${session.active ? 'off' : 'on'}-button`"
        style="margin-right: -5px"
        v-model="session.active"
        color="#2b8a2b"
        :loading="isLoading"
        :disabled="isLoading || (role === 'admin' && !session.active)"
        @click.stop.prevent="sessionButtonClick(session)"
      />
      <v-btn
        v-else
        :id="`recent-session-${session.id}-turn-${session.active ? 'off' : 'on'}-button`"
        small
        class="plugzio-button session-button"
        :class="{ 'button-off': !session.active }"
        outline
        round
        @click="sessionButtonClick(session)"
        :loading="isLoading"
        :disabled="isLoading || (role === 'admin' && !session.active)"
      >
        {{ session.active ? "TURN OFF" : "TURN ON" }}
      </v-btn>
    </v-list-tile-action>
  </v-list-tile>
</template>
<script>
import mixin from "@/mixins";
import sessions from "@/mixins/sessions";
import Api from "@/library/apis/Api";
import { mapState } from "vuex";
import VTooltipHandler from "@/components/VTooltipHandler";
export default {
  mixins: [mixin, sessions],
  props: {
    session: {
      required: true,
    },
  },
  components: {
    VTooltipHandler,
  },
  data() {
    return {
      process: null,
      processedTimeout: 0,
      processedDevice: undefined,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      responsive: (state) => state.responsive,
      processedDevices: (state) => state.Session.processedDevices,
      processingTimeout: (state) => state.Session.processingTimeout,
      role: (state) => state.Auth.role
    }),
    isLoading() {
      return !!this.processedDevice || this.loading || this.sessionLoading;
    },
    icon() {
      const icons = ["plug-off.png", "plug-on.png", "not-allowed.svg"];
      return icons[this.session.statusCode];
    },
  },
  watch: {
    processedDevice(newValue, oldValue) {
      if (!newValue) return this.$store.commit("Session/removeProcessedDevices", this.session.plug_identifier);
      this.$store.commit("Session/modifyProcessedDevices", newValue);
      if (newValue != oldValue && this.processedTimeout != newValue.timeout) this.getProcessedDeviceSessions();
    },
    session: {
      handler(newValue, oldValue) {
        if (newValue.plug_identifier != oldValue.plug_identifier || newValue.active != oldValue.active) {
          this.processedDevice = undefined;
          clearTimeout(this.process);
        }
      },
      deep: true,
    },
  },
  methods: {
    getProcessedDeviceSessions() {
      if (!this.processedDevice) return;
      if (this.processedDevice.timeout <= 0) {
        console.error(`Timeout reached: ${JSON.stringify(this.processedDevice)}`);
        this.$emit("error", this.processedDevice);
        this.processedDevice = undefined;
        return;
      }
      this.processedTimeout = this.processedDevice.timeout;
      this.getSessions();
      const processedDevice = { identifier: this.session.plug_identifier, timeout: this.processedDevice.timeout - 10, process: this.processedDevice.process };
      this.process = setTimeout(() => {
          this.processedDevice = this.processedDevices.map(v => v.identifier).includes(processedDevice.identifier)
            ? processedDevice
            : undefined;
      }, 10000);
    },
    stopSession(plugIdentifier) {
      this.$store.dispatch("loading", true); 
      
      const killSessionRequest = (params) => this.role === "admin" ? Api.adminPlugsessionkill(params) : Api.sessionsEnd(params);

      killSessionRequest({ plugIdentifier: plugIdentifier })
        .then((response) => {
          this.$store.dispatch("snackbar", { message: response });
          this.processedDevice = { identifier: this.session.plug_identifier, timeout: this.processingTimeout, process: "stop" };
          this.getProcessedDeviceSessions();
        })
        .catch((error) => console.log(error))
        .finally(() => this.$store.dispatch("loading", false));
    },
    sessionButtonClick(session) {
      if (session.active) this.stopSession(session.plug_identifier);
      else this.$router.push(`/activate-device/${session.plug_identifier}`);
    },
  },
  mounted() {
    this.processedDevice = this.processedDevices.find((d) => d.identifier == this.session.plug_identifier);
  },
  beforeDestroy() {
    this.$store.commit("Session/setProcessedDevices", []);
  },
};
</script>

<style lang="scss">
.session-stale {
  border-color: rgba(0, 0, 0, 1) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
  &-info {
    margin-top: 0px;
    top: 0px;
  }
}
</style>
