<template>
  <div>
    <h4 class="mb-4">You Have No Devices</h4>
    <div class="notice-description text-xs-left mb-3">
      <div class="mb-3">
        This page is to manage the subscription of your devices to the Plugzio Dashboard. To use this page, please register a device on the
        <strong>
          <a class="plugzio-color--text" @click.stop.prevent="$router.push({ name: `${modeRoute}outlet-management` })">Device Management</a>
        </strong>
        page.
      </div>
      <div class="mb-3">Registered devices will automatically appear on this page.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mode-route"],
};
</script>
