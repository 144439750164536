<template>
  <v-flex class="px-3">
    <v-card :max-width="width || 700" class="center-margin pa-4 text-xs-center elevation-0 notice" v-if="data === false">
      <slot></slot>
    </v-card>
    <v-progress-circular v-else class="loading-circle center-margin" size="50" indeterminate color="orange" />
  </v-flex>
</template>

<script>
export default {
  props: {
    data: {
      type: Boolean,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.notice
  background: transparent
</style>
