<template>
  <div>
    <v-card class="ma-2">
      <v-card-title>
        <h3 id="recent-sessions-tile">Recent Sessions</h3>
        <v-icon
          id="recent-sessions-tile-info"
          class="tooltip-icon ml-2"
          v-tooltip="{
            content: 'Recent device sessions',
            placement: 'top-center',
            trigger: 'click hover',
          }"
          >info</v-icon
        >
        <v-spacer></v-spacer>
        <v-progress-circular v-if="sessionsLoading" indeterminate color="orange" :size="24"></v-progress-circular>
        <v-icon v-else id="recent-sessions-tile-refresh-button" class="pointer" @click="getSessions()">refresh</v-icon>
      </v-card-title>
      <v-card-text class="session-list">
        <div class="mt-3" v-if="sessionsLoaded">
          {{ sessionsLoaded }}
        </div>
        <v-list three-line>
          <RecentSessionTile :ref="`recentSessionTile-${session.plug_identifier}`" v-for="(session, i) in recentSessions" :key="i" :session="session" @error="showFailedDialog" />
        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog v-model="failedDialog.show" max-width="300">
      <v-card>
        <v-card-title>
          <h3>Charging Session Timeout</h3>
        </v-card-title>
        <v-card-text>
          Charging session on device <strong>{{ failedDialog.device ? failedDialog.device.identifier : "" }}</strong> has failed to
          {{ failedDialog.device ? failedDialog.device.process : "" }}. Device maybe offline.
          <br />
          Please try again or contact customer support.
        </v-card-text>
        <v-card-actions>
          <v-btn color="gray" flat="flat" @click="failedDialog.show = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat="flat" @click="retryFailed">
            Retry
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mixin from "./../../mixins";
import sessions from "./../../mixins/sessions";
import RecentSessionTile from "./RecentSessionTile.vue";
import { mapState } from "vuex";
export default {
  name: "Recent-Session-Card",
  mixins: [mixin, sessions],
  components: {
    RecentSessionTile,
  },
  data() {
    return {
      failedDialog: {
        show: false,
        device: null,
      },
    };
  },
  computed: {
    ...mapState({
      processedDevices: (state) => state.Session.processedDevices,
    }),
    recentSessions() {
      let newSessions = [];
      newSessions = this.sessions.sort((a, b) => b.endtime - a.endtime).sort((a, b) => b.starttime - a.starttime);
      newSessions = newSessions.filter((obj, index, self) => index === self.findIndex((t) => t["plug_id"] === obj["plug_id"]));

      newSessions = newSessions.sort((a, b) => b.statusCode - a.statusCode);
      for (let i = 0; i < this.processedDevices.length; i++) {
        const device = this.processedDevices[i];
        if (!!newSessions.find((s) => s.plug_identifier == device.identifier)) continue;
        newSessions.unshift({
          plug_identifier: device.identifier,
          active: false,
          status: "OFF",
          statuscode: 0,
        });
      }
      return newSessions;
    },
  },
  methods: {
    showFailedDialog(device) {
      this.failedDialog.show = true;
      this.failedDialog.device = device;
    },
    retryFailed() {
      this.failedDialog.show = false;
      if (this.failedDialog.device.process == "start") return this.$router.push(`/activate-device/${this.failedDialog.device.identifier}`);
      else if (this.failedDialog.device.process == "stop") {
        const refname = `recentSessionTile-${this.failedDialog.device.identifier}`;
        this.$refs[refname][0].stopSession(this.failedDialog.device.identifier);
        return;
      }
      console.error(`Failed to retry, device data invalid: ${JSON.stringify(this.failedDialog.device)}`);
    },
  },
};
</script>
